<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Amchara Bookings</h1>
        <p class="">This area is being updated!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
};
</script>

<style>
</style>